import "core-js/modules/es.function.name.js";
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue";
var _hoisted_1 = {
  class: "flex-col"
};
var _hoisted_2 = {
  class: "flex-col"
};
var _hoisted_3 = {
  class: "flex-col"
};
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import AddToCartBtn from '@/components/AddToCartBtn';
export default {
  setup: function setup(__props) {
    var router = useRouter();
    var store = useStore();
    var fsData = store.getters.getFsData;

    var redirectInProduction = function redirectInProduction() {
      if (process.env.VUE_APP_SERVER === 'production') {
        router.push({
          name: 'PageNotFound'
        });
      }
    };

    redirectInProduction();
    return function (_ctx, _cache) {
      var _component_el_row = _resolveComponent("el-row");

      var _component_el_col = _resolveComponent("el-col");

      return _openBlock(), _createBlock(_component_el_row, {
        align: "middle",
        justify: "center"
      }, {
        default: _withCtx(function () {
          return [_createVNode(_component_el_col, null, {
            default: _withCtx(function () {
              return [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(fsData), function (v, k) {
                return _openBlock(), _createBlock(_component_el_row, {
                  align: "middle",
                  justify: "space-between",
                  key: k,
                  style: {
                    "padding": "6px"
                  }
                }, {
                  default: _withCtx(function () {
                    return [_createElementVNode("div", _hoisted_1, _toDisplayString(v.name), 1), _createElementVNode("div", _hoisted_2, _toDisplayString(v.path), 1), _createElementVNode("div", _hoisted_3, [_createVNode(_unref(AddToCartBtn), {
                      productPath: v.path
                    }, null, 8, ["productPath"])])];
                  }),
                  _: 2
                }, 1024);
              }), 128))];
            }),
            _: 1
          })];
        }),
        _: 1
      });
    };
  }
};